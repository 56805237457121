<template>
<ClientOnly>
    <Swiper
        class="media-tile-container"
        :grab-cursor="true"
        :speed="150"
        :init="false"
        :modules="[SwiperNavigation]"
        :navigation="{ enabled: true }"
        :breakpoints="breakpoints"
        :space-between="16"
    >
        <SwiperSlide v-for="(promo, idx) in media" :key="idx">
            <div
                role="button" tabindex="0" class="img-wrapper"
                v-on:click="mediaClick(idx)"
                v-on:keyup.enter="mediaClick(idx)"
            >
                <img
                    class="media-tile"
                    role="button"
                    tabindex="0"
                    :alt="getAltText(promo)"
                    :src="`${promo.attributes.url}?format=auto&width=600`"
                    draggable="false"
                    loading="lazy"
                    v-on:click="mediaClick(idx)"
                    v-on:keyup.enter="mediaClick(idx)"
                >
                <img
                    v-if="firstImgTrailer && idx === 0"
                    :alt="$t('WIDGETS.PLAY')"
                    class="play-btn"
                    src="../../assets/play_btn.svg"
                >
            </div>
        </SwiperSlide>
    </Swiper>
</ClientOnly>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import type { CMS } from '$plugins/cms'
import type { JBGWeb } from '$types/JBGWeb'

export default defineComponent({
    props: {
        media: {
            type: Object as Prop<CMS.Data<JBGWeb.Media>[]>
        },
        firstImgTrailer: {
            type: String,
            required: false
        }
    },

    emits: {
        itemClicked: (_: any) => true
    },

    data() {
        return {
            breakpoints: {
                1400: {
                    slidesPerView: 4.25
                },
                1265: {
                    slidesPerView: 3.25,
                    slidesOffsetBefore: 40,
                    slidesOffsetAfter: 40
                },
                960: {
                    slidesPerView: 2.25,
                    slidesOffsetBefore: 16,
                    slidesOffsetAfter: 16
                },
                600: {
                    slidesPerView: 1.5,
                    slidesOffsetBefore: 16,
                    slidesOffsetAfter: 16
                },
                320: {
                    slidesPerView: 1,
                    slidesOffsetBefore: 14,
                    slidesOffsetAfter: 14
                }
            }
        }
    },

    methods: {
        mediaClick(index: number) {
            this.$emit('itemClicked', index)
        },

        getAltText(promo: CMS.Data<JBGWeb.Media>): string {
            if (!promo.attributes.alternativeText) return this.$t('WIDGETS.FALLBACK_IMG_ALT_TEXT')
            return promo.attributes.alternativeText
        }
    }
})

</script>

<style lang="scss" scoped>
@use "$styles/kit.scss" as *;

.media-tile-container {
    padding-bottom: 28px;

    .img-wrapper {
        position: relative;
        display: flex;
        align-items: center;

        &:hover {
                outline: solid 4px var(--primary-50);
                outline-offset: -4px;
                border-radius: 8px;
                cursor: pointer;
            }

        .media-tile {
            width: 100%;
            height: 100%;
        }

        .play-btn {
            position: absolute;
            margin-left: 40%;

            @include mq-small-and-less {
                height: 80px;
                width: 80px;
            }
        }
    }
}

:deep() {
    @import '$styles/swiper';
}
</style>
